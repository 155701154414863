
import { computed, defineComponent } from '~/bridge'
import useMainSnackbarStore from '~/stores/useMainSnackbarStore'

export default defineComponent({
  name: 'MainSnackbar',
  setup() {
    const snackbar = useMainSnackbarStore()

    const timeout = computed(() => snackbar.cancelText ? 7000 : 4000)

    function cancelHandler() {
      if (snackbar.isActive && !snackbar.cancelText) {
        throw new Error('Не передан cancelable.')
      }

      snackbar.cancelHandler?.()
      snackbar.close()
    }

    return {
      snackbar,
      timeout,
      cancelHandler,
    }
  },
})
