import { infoClient } from '~/core/utils/clients/infoClient'
import { FullName } from '~/core/types'
import { profileClient } from '~/core/utils/clients/profileClient'
import { masterClient } from '~/core/utils/clients/masterClient'
import { ProfileSettingsDto } from '~/core/api/email.api'
import { ProfileDeletableStatus } from '~/features/Profile/enums/profile'
import { Gender } from '~/features/Profile/enums'

const RESOURCE = '/profile'

export type ProfileDto = FullName & {
  gender: Gender
}

export type ProfileClubCardDto = {
  idProdoctorov: number
  dtStart: string
  dtEnd: string
}

type ProfileMainDto = {
  dtBirth: string
  townName: string
  clubCard: ProfileClubCardDto | null
}

export type ProfileInfoDto = ProfileSettingsDto & {
  email: string
  hasLoyaltyCards: boolean
  isFemaleCalendarEnabled: boolean
}

type ProfileDeletableInfoDto = {
  profileDeletableStatus: ProfileDeletableStatus.canBeDeleted
} | {
  profileDeletableStatus: ProfileDeletableStatus.hasPaidTelemedAppointment
  appointmentId: number
}

export default {
  getProfile() {
    return profileClient.get<ProfileDto>(`${RESOURCE}/`, { camelize: true })
  },
  getProfileFromMaster() {
    return masterClient.get<ProfileMainDto>(`${RESOURCE}/`, { camelize: true })
  },
  getProfileFromInfo() {
    return infoClient.get<ProfileInfoDto>(`${RESOURCE}/info/`, { camelize: true })
  },
  delete(comment: string) {
    return masterClient.post(`${RESOURCE}/delete/`, { comment }, { camelize: true })
  },
  getProfileDeletableInfo() {
    return masterClient.get<ProfileDeletableInfoDto>(`${RESOURCE}/deletable/`, { camelize: true })
  },
  getSavedMoney() {
    return masterClient.get<{ moneySavedByClub: number }>(`${RESOURCE}/money_saved_by_club/`, { camelize: true })
  },
}
