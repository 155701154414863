import { defineStore } from 'pinia'
import { ref } from '~/bridge'

export type UseMainSnackbarOpenPayload = {
  text: string
  cancelText?: string
  cancelHandler?: Function | undefined
  dontCloseOnRouteChange?: boolean
}

export default defineStore('mainSnackbar', () => {
  const isActive = ref(false)
  const text = ref('')
  const cancelText = ref('')
  const cancelHandler = ref<Function | undefined>(undefined)
  const dontCloseOnRouteChange = ref(false)

  function open(payload: UseMainSnackbarOpenPayload) {
    isActive.value = true
    text.value = payload.text ?? ''
    cancelText.value = payload.cancelText ?? ''
    cancelHandler.value = payload.cancelHandler
    dontCloseOnRouteChange.value = payload.dontCloseOnRouteChange || false
  }

  function close() {
    isActive.value = false
    text.value = ''
    cancelText.value = ''
    cancelHandler.value = undefined
    dontCloseOnRouteChange.value = false
  }

  return {
    isActive,
    text,
    cancelText,
    cancelHandler,
    dontCloseOnRouteChange,
    open,
    close,
  }
})
