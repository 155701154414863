import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { ProfileApi as AgreementApi } from '~/core/api'
import { composeFullName, removeNamespace } from '~/core/functions'
import { PD_URL, PROFILE_ACTIONS, PROFILE_GETTERS, PROFILE_MUTATIONS } from '~/core/constants'
import { TownSelectItemDto } from '~/core/components/TownSelect/api'
import { pluralize } from '~/core/utils/pluralize'
import {
  appointmentDeclension,
  doctorDeclension,
  lpuDeclension,
  rateDeclension,
  readerDeclension,
  serviceDeclension,
} from '~/core/constants/wordForms'
import { getFormattedPhone } from '~/core/utils/getFormattedPhone'
import { isFeatureEnabled } from '~/core/utils/isFeatureEnabled'
import { AgreementStatus, Gender } from '~/features/Profile/enums'
import { ProfileApi } from '~/features/Profile/api'
import profileApi, { ProfileClubCardDto, ProfileInfoDto } from '~/features/Profile/api/profile.api'
import { ProfileInformationBlock } from '~/features/Profile/types'
import appointmentListApi from '~/features/Appointments/api/appointmentList.api'
import rateApi from '~/features/Rates/api/rate.api'
import hitsApi from '~/features/Rates/api/hits.api'
import { Country } from '~/features/Authorization/api/auth.api'

/**
 * Todo:
 * здесь должна быть функция-фабрика, которая возвращает default state,
 * но мы тогда теряем intelliSense у редактора при обращении к state
 */
export const state = () => ({
  phone: '',
  townConfirmed: false,
  town: '',
  townKey: '',
  townId: -1,
  gender: '' as Gender,
  forename: '',
  patronymic: '',
  surname: '',
  email: '',
  dateBirth: null,
  agreementStatus: AgreementStatus.Default,
  preventShowingAgreement: false,
  afterAgreementCallback: null as Function | null,
  clubCard: null as ProfileClubCardDto | null,
  unreadNotificationsCount: 0,
  medcardActiveSessionsCount: 0,
  allowReceiveNewsEmails: true,
  allowReceiveMedcardEmails: true,
  hasLoyaltyCards: false,
  isFemaleCalendarEnabled: true,
  informationBlock: null as ProfileInformationBlock | null,
  country: null as Country | null,
})

const GETTERS_TYPES = removeNamespace('profile/', PROFILE_GETTERS)

const MUTATIONS_TYPES = removeNamespace('profile/', PROFILE_MUTATIONS)

const ACTIONS_TYPES = removeNamespace('profile/', PROFILE_ACTIONS)

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, any> = {
  [GETTERS_TYPES.COMPOSED_TOWN_OBJECT](state): TownSelectItemDto {
    return {
      key: state.townKey,
      id: state.townId,
      name: state.town,
    }
  },
  [GETTERS_TYPES.GET_FULL_NAME](state): string {
    return composeFullName(state)
  },
  [GETTERS_TYPES.IS_PROFILE_FILLED](state): boolean {
    return Boolean(state.gender && state.surname && state.patronymic && state.forename && state.dateBirth)
  },
  [GETTERS_TYPES.FORMATTED_PHONE](state): string {
    if (!state.phone) {
      return ''
    }

    if (state.country) {
      const { maskForInput: mask, phoneCode } = state.country

      return getFormattedPhone(state.phone, { mask, phoneCode })
    }

    return getFormattedPhone(state.phone)
  },
  [GETTERS_TYPES.PD_URL](state): string {
    return state.country?.pdDomain ? `https://${state.country.pdDomain}` : PD_URL
  },
}

export const mutations: MutationTree<RootState> = {
  [MUTATIONS_TYPES.SET_MEDCARD_ACTIVE_SESSIONS_COUNT](state, payload) {
    state.medcardActiveSessionsCount = payload
  },
  [MUTATIONS_TYPES.SET_UNREAD_NOTIFICATIONS_COUNT](state, payload) {
    state.unreadNotificationsCount = payload
  },
  [MUTATIONS_TYPES.SET_PHONE](state, payload) {
    state.phone = payload
  },
  [MUTATIONS_TYPES.SET_CLUB_CARD](state, payload) {
    state.clubCard = payload
  },
  [MUTATIONS_TYPES.SET_TOWN](state, payload) {
    state.town = payload.town
    state.townKey = payload.townKey
    state.townId = payload.townId
  },
  [MUTATIONS_TYPES.SET_GENDER](state, payload) {
    state.gender = payload
  },
  [MUTATIONS_TYPES.SET_BIRTH](state, payload) {
    state.dateBirth = payload
  },
  [MUTATIONS_TYPES.SET_TOWN_CONFIRMED](state, payload) {
    state.townConfirmed = payload
  },
  [MUTATIONS_TYPES.SET_EMAIL](state, payload) {
    state.email = payload
  },
  [MUTATIONS_TYPES.SET_FULL_NAME](state, payload) {
    state.forename = payload.forename || ''
    state.patronymic = payload.patronymic || ''
    state.surname = payload.surname || ''
  },
  [MUTATIONS_TYPES.SET_AGREEMENT_STATUS](state, payload: AgreementStatus) {
    state.agreementStatus = payload
  },
  [MUTATIONS_TYPES.SET_PREVENT_SHOWING_AGREEMENT](state, payload: boolean) {
    state.preventShowingAgreement = payload
  },
  [MUTATIONS_TYPES.SET_AFTER_AGREEMENT_CALLBACK](state, payload: Function | null) {
    state.afterAgreementCallback = payload
  },
  [MUTATIONS_TYPES.SET_COUNTRY](state, payload: Country | null) {
    state.country = payload
  },
  [MUTATIONS_TYPES.ALLOW_RECEIVE_NEWS_EMAILS](state, payload: boolean) {
    state.allowReceiveNewsEmails = payload
  },
  [MUTATIONS_TYPES.ALLOW_RECEIVE_MEDCARD_EMAILS](state, payload: boolean) {
    state.allowReceiveMedcardEmails = payload
  },
  [MUTATIONS_TYPES.SET_HAS_LOYALTY_CARDS](state, payload: boolean) {
    state.hasLoyaltyCards = payload
  },
  [MUTATIONS_TYPES.SET_IS_FEMALE_CALENDAR_ENABLED](state, payload: boolean) {
    state.isFemaleCalendarEnabled = payload
  },
  [MUTATIONS_TYPES.SET_INFORMATION_BLOCK](state, payload: ProfileInformationBlock) {
    state.informationBlock = payload
  },
}

export const actions: ActionTree<RootState, any> = {
  async [ACTIONS_TYPES.RULES_AGREE]({ commit }) {
    commit(MUTATIONS_TYPES.SET_AGREEMENT_STATUS, AgreementStatus.Agreed)
    commit(MUTATIONS_TYPES.SET_AFTER_AGREEMENT_CALLBACK, null)
    await AgreementApi.rulesAgree()
  },
  async [ACTIONS_TYPES.FETCH_PROFILE]({ commit, rootState }) {
    const [profileResponse, masterResponse, infoResponse] = await Promise.all([
      ProfileApi.getProfile(),
      ProfileApi.getProfileFromMaster(),
      ProfileApi.getProfileFromInfo(),
    ])

    commit(MUTATIONS_TYPES.SET_HAS_LOYALTY_CARDS, (infoResponse.data as ProfileInfoDto).hasLoyaltyCards)

    if (isFeatureEnabled('sff_disabling_female_calendar', rootState.featureFlags)) {
      commit(MUTATIONS_TYPES.SET_IS_FEMALE_CALENDAR_ENABLED, infoResponse.data.isFemaleCalendarEnabled)
    }

    commit(MUTATIONS_TYPES.SET_EMAIL, infoResponse.data.email)
    commit(MUTATIONS_TYPES.ALLOW_RECEIVE_NEWS_EMAILS, infoResponse.data?.allowReceiveNewsEmails)
    commit(MUTATIONS_TYPES.ALLOW_RECEIVE_MEDCARD_EMAILS, infoResponse.data?.allowReceiveMedcardEmails)
    commit(MUTATIONS_TYPES.SET_FULL_NAME, profileResponse.data)
    commit(MUTATIONS_TYPES.SET_GENDER, profileResponse.data?.gender)
    commit(MUTATIONS_TYPES.SET_BIRTH, masterResponse.data?.dtBirth)
    commit(MUTATIONS_TYPES.SET_CLUB_CARD, masterResponse.data?.clubCard)
  },
  async [ACTIONS_TYPES.FETCH_INFORMATION_BLOCK]({ commit }) {
    let doctorAppointmentsCount = 0
    let serviceAppointmentsCount = 0
    let doctorRatesCount = 0
    let lpuRatesCount = 0
    let savedMoney = 0
    let allHits = 0
    let newHits = 0

    try {
      const [
        { data: appointmentsData },
        { data: ratesData },
        { data: profileData },
        { data: hitsData },
      ] = await Promise.all([
        appointmentListApi.getAppointmentsCount(),
        rateApi.getRatesCount(),
        profileApi.getSavedMoney(),
        hitsApi.getRatesHit(),
      ])

      doctorAppointmentsCount = appointmentsData.doctorAppointmentsCount
      serviceAppointmentsCount = appointmentsData.serviceAppointmentsCount
      doctorRatesCount = ratesData.doctorRatesCount
      lpuRatesCount = ratesData.lpuRatesCount
      savedMoney = profileData.moneySavedByClub
      allHits = hitsData?.total.allHits || 0
      newHits = hitsData?.total.newAllHits || 0
    } catch {}
    // NOTE: Не выбрасываем ошибку. Если что-то не удастся запросить, то просто покажем нули в данных

    commit(MUTATIONS_TYPES.SET_INFORMATION_BLOCK, {
      appointments: {
        totalCount: doctorAppointmentsCount + serviceAppointmentsCount,
        text: pluralize(
          doctorAppointmentsCount + serviceAppointmentsCount,
          appointmentDeclension,
          { withValue: false },
        ),
        doctors: pluralize(doctorAppointmentsCount, doctorDeclension, { locale: true, withValue: true }),
        services: pluralize(serviceAppointmentsCount, serviceDeclension, { locale: true, withValue: true }),
      },
      rates: {
        totalCount: doctorRatesCount + lpuRatesCount,
        text: pluralize(doctorRatesCount + lpuRatesCount, rateDeclension, { withValue: false }),
        doctors: pluralize(doctorRatesCount, doctorDeclension, { locale: true, withValue: true }),
        lpus: pluralize(lpuRatesCount, lpuDeclension, { locale: true, withValue: true }),
      },
      savedMoney,
      hits: {
        newHits,
        allHits,
        text: pluralize(allHits, readerDeclension, { withValue: false }),
      },
    })
  },
}
