// https://typescript.nuxtjs.org/cookbook/plugins/#iii-combined-inject
import { Context, Plugin } from '@nuxt/types'
import { isFeatureEnabled } from '~/core/utils/isFeatureEnabled'

export type FeatureFlag = (
  'sff_medcard_show_document_from_mis'
  | 'sff_rates_redesign'
  | 'sff_supplemented_at_waiting_rates'
  | 'sff_cis'
  | 'sff_feedback'
  | 'sff_telemed_recording'
  | 'sff_new_calendar_slots'
  | 'sff_telemed_completion_info'
  | 'sff_compress_photos'
  | 'sff_visible_questions_for_doctor'
  | 'sff_medcard_available_sharing_sessions'
  | 'sff_disabling_female_calendar'
  | 'sff_disable_captcha_validation_for_office'
  | 'sff_confirm_appointment_without_authorization'
)

declare module 'vue/types/vue' {
  // this.$featureFlag inside Vue components
  interface Vue {
    $featureFlag(feature: FeatureFlag): boolean
  }
}

declare module '@nuxt/types' {
  // nuxtContext.$featureFlag
  interface Context {
    $featureFlag(feature: FeatureFlag): boolean
  }
}

declare module 'vuex/types/index' {
  // this.$featureFlag inside Vuex stores
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $featureFlag(feature: FeatureFlag): boolean
  }
}

const featureFlags: Plugin = ({ store }: Context, inject) => {
  inject('featureFlag', (feature: FeatureFlag) => {
    return isFeatureEnabled(feature, store.state.featureFlags)
  })
}

export default featureFlags
