/**
 * Зарезервированные query параметры url'а.
 * При переходе на определенные страницы и наличии этих параметров в query будут выполняться определенные действия.
 * */
export enum ReservedQueryKey {
  Token = 'token',
  NotificationId = 'notification_id',
  IdempotencyKey = 'idempotency_key',
  MedcardToken = 'medcard-token',
  ReferralDoctorId = 'referral_doctor_id',
  LpuHash = 'lpu_hash',
  OpenApp = 'open_app',
  DeeplinkOnly = 'deeplink_only',
  LpuSource = 'source',
  PreselectedCountry = 'preselected_country',
  ConfirmationToken = 'confirmation_token'
}
