
import VClamp from 'vue-clamp'
import { isArray } from 'lodash-es'
import { pluralize } from '~/core/utils/pluralize'
import { documentDeclension } from '~/core/constants/wordForms'
import { CORE_GETTERS } from '~/core/constants'
import { DOCUMENTS_ACTIONS, DOCUMENTS_GETTERS } from '~/core/constants/store/medcard/documents'
import { MedcardSessionData } from '~/core/api/mtlink.api'
import { computed, defineComponent, ref, useNuxtApp, watch, reactive } from '~/bridge'
import { MedcardFolderDto } from '~/features/Medcard/api/folders.api'
import NewFolderPopup from '~/features/Medcard/components/folders/NewFolderPopup.vue'

type MedcardFolderWithCheckbox = MedcardFolderDto & { documentsText: string }

export type MedcardShareSelectItemsExpose = {
  changed: boolean,
  selected: boolean,
  selectedItems: MedcardSessionData,
  resetState: (state?: MedcardSessionData) => void
}

export default defineComponent({
  name: 'MedcardShareSelectItems',
  components: {
    NewFolderPopup,
    VClamp,
  },
  props: {
    withAddFolders: { type: Boolean, default: false },
  },
  setup(_, { expose }) {
    const isNewFolderPopupActive = ref(false)
    const allFoldersCheckboxValue = ref(false)
    const femaleCalendarCheckboxValue = ref(false)
    const selectedFoldersIds = ref<number[]>([])
    const selectedFoldersIdsCopy = ref<number[]>([])

    const oldState = reactive({
      allFoldersCheckboxValue: allFoldersCheckboxValue.value,
      femaleCalendarCheckboxValue: femaleCalendarCheckboxValue.value,
      selectedFoldersIds: [...selectedFoldersIds.value],
    })

    const { $store, $featureFlag } = useNuxtApp()

    const documentsCount = computed(() =>
      pluralize($store.getters[DOCUMENTS_GETTERS.GET_MEDCARD_DOCUMETS_COUNT], documentDeclension))

    const folders = computed<MedcardFolderWithCheckbox[]>(() =>
      $store.state.medcard.documents.folders.map((item: MedcardFolderDto) => ({
        ...item,
        documentsText: pluralize(item.documentGroups.length || 0, documentDeclension),
      })),
    )

    const femaleCalendarId = computed(() => {
      const activeProfile = $store.getters[CORE_GETTERS.GET_FAMILY_PROFILE]($store.state.medcard.familyProfileUuid)

      return activeProfile?.femaleCalendarId
    })

    const changed = computed(() => {
      return oldState.femaleCalendarCheckboxValue !== femaleCalendarCheckboxValue.value ||
        oldState.allFoldersCheckboxValue !== allFoldersCheckboxValue.value ||
        oldState.selectedFoldersIds.length !== selectedFoldersIds.value.length ||
        !oldState.selectedFoldersIds.every(val => selectedFoldersIds.value.includes(val))
    })

    const selected = computed(() =>
      allFoldersCheckboxValue.value ||
      selectedFoldersIds.value.length > 0 ||
      femaleCalendarCheckboxValue.value)

    const isFemaleCalendarDisabled = computed(
      () => $featureFlag('sff_disabling_female_calendar') && !$store.state.profile.isFemaleCalendarEnabled,
    )

    const selectedItems = computed(() => ({
      folderIds: allFoldersCheckboxValue.value ? undefined : selectedFoldersIds.value,
      femaleCalendarId: femaleCalendarCheckboxValue.value && !isFemaleCalendarDisabled.value
        ? femaleCalendarId.value
        : null,
    }))

    watch(allFoldersCheckboxValue, (val) => {
      if (val) {
        selectedFoldersIdsCopy.value = [...selectedFoldersIds.value]
        selectedFoldersIds.value = []
      } else {
        selectedFoldersIds.value = [...selectedFoldersIdsCopy.value]
      }
    })

    watch(folders, (val) => {
      allFoldersCheckboxValue.value = !val.length
    }, { immediate: true })

    function resetState({ folderIds, femaleCalendarId }: MedcardSessionData = {}) {
      allFoldersCheckboxValue.value = !isArray(folderIds)
      femaleCalendarCheckboxValue.value = Boolean(femaleCalendarId)
      selectedFoldersIds.value = folderIds ? [...folderIds] : []
      selectedFoldersIdsCopy.value = [...selectedFoldersIds.value]

      oldState.femaleCalendarCheckboxValue = femaleCalendarCheckboxValue.value
      oldState.allFoldersCheckboxValue = allFoldersCheckboxValue.value
      oldState.selectedFoldersIds = [...selectedFoldersIdsCopy.value]
    }

    function handleFolderCreated() {
      $store.dispatch(DOCUMENTS_ACTIONS.FETCH_MEDCARD_FOLDERS)
    }

    expose({
      changed,
      selected,
      selectedItems,
      resetState,
    })

    return {
      isNewFolderPopupActive,
      allFoldersCheckboxValue,
      femaleCalendarCheckboxValue,
      selectedFoldersIds,
      documentsCount,
      folders,
      femaleCalendarId,
      isFemaleCalendarDisabled,
      handleFolderCreated,

      // Для тестов
      selected,
      selectedItems,
    }
  },
})
