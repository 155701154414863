import { RawLocation } from 'vue-router'
import { useRoute, useRouter } from '~/bridge'

export const useGoBack = (backRoute?: RawLocation) => {
  const $route = useRoute()
  const $router = useRouter()

  return () => {
    if ($route.params.from) {
      $router.push($route.params.from)
    } else if (backRoute) {
      $router.push(backRoute)
    } else {
      $router.back()
    }
  }
}
